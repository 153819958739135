import {createApp} from 'vue'
import App from './App.vue'
import router from './router'

import {BootstrapVue3, IconsPlugin} from 'bootstrap-vue-3'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@/plugins/font-awesome";
import VueHtmlToPaper from './plugins/VueHtmlToPaper'


const optionsVueHtmlToPaper = {
    name: '_blank',
    specs: [
        'fullscreen=yes',
        'titlebar=yes',
        'scrollbars=yes'
    ],
    styles: [
        'https://cdn.jsdelivr.net/npm/bootstrap@5.2.2/dist/css/bootstrap.min.css',
    ],
    timeout: 1000, // default timeout before the print window appears
    autoClose: true, // if false, the window will not close after printing
    windowTitle: window.document.title, // override the window title
}

library.add(faUserSecret)


createApp(App)
    .use(BootstrapVue3)
    .use(router)
    .use(VueHtmlToPaper, optionsVueHtmlToPaper)
    .component('fa', FontAwesomeIcon)
    .use(IconsPlugin)
    .mount('#app')
